import React, { useEffect, useState } from "react";

const OPENWEATHER_API_KEY = process.env.REACT_APP_OPENWEATHER_API_KEY;
const IPINFO_URL = process.env.REACT_APP_IPINFO_URL;

function WeatherWidget() {
  const [city, setCity] = useState("");
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [theme, setTheme] = useState(document.documentElement.getAttribute("data-theme") || "dark");

  // Helper function to get and set localStorage data with expiry
  const getLocalStorageData = (key) => {
    const item = localStorage.getItem(key);
    if (item) {
      const { data, timestamp } = JSON.parse(item);
      const oneDay = 24 * 60 * 60 * 1000; // 1 day in milliseconds
      if (Date.now() - timestamp < oneDay) {
        return data;
      }
    }
    return null;
  };

  const setLocalStorageData = (key, data) => {
    localStorage.setItem(key, JSON.stringify({ data, timestamp: Date.now() }));
  };

  // 1. Detect theme changes dynamically
  useEffect(() => {
    const observer = new MutationObserver(() => {
      setTheme(document.documentElement.getAttribute("data-theme") || "dark");
    });

    observer.observe(document.documentElement, { attributes: true, attributeFilter: ["data-theme"] });

    return () => observer.disconnect();
  }, []);

  // 2. Get the user’s city from IP info (or use localStorage)
  useEffect(() => {
    const cachedCity = getLocalStorageData("user_city");
    if (cachedCity) {
      setCity(cachedCity);
      return;
    }

    async function fetchCity() {
      try {
        const response = await fetch(IPINFO_URL);
        if (!response.ok) {
          throw new Error("Failed to fetch IP info");
        }
        const data = await response.json();
        const userCity = data.city; // Fallback
        if (userCity) {
          setCity(userCity);
          setLocalStorageData("user_city", userCity);
        }
        // setCity(userCity);
        // setLocalStorageData("user_city", userCity);
      } catch (err) {
        // setError("Unable to get location");
        console.error(err);
      }
    }
    fetchCity();
  }, []);

  // 3. Fetch weather data (or use localStorage)
  useEffect(() => {
    if (!city) return;

    const cachedWeather = getLocalStorageData(`weather_${city}`);
    if (cachedWeather) {
      setWeatherData(cachedWeather);
      setLoading(false);
      return;
    }

    async function fetchWeather() {
      try {
        setLoading(true);
        const url = `https://api.openweathermap.org/data/2.5/weather?q=${city},IN&appid=${OPENWEATHER_API_KEY}&units=metric`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch weather");
        }
        const data = await response.json();
        if (data.cod !== 200) {
          throw new Error(data.message || "Error fetching weather data");
        }
        setWeatherData(data);
        setLocalStorageData(`weather_${city}`, data);
      } catch (err) {
        setError(`Unable to fetch weather for ${city}: ${err.message}`);
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
    fetchWeather();
  }, [city]);

  if (error) {
    return <p className="weather-error">{error}</p>;
  }

  if (loading) {
    return <p className="weather-loading">Loading weather data...</p>;
  }

  if (!weatherData) {
    return null;
  }

  // Extract relevant data
  const { name } = weatherData;
  const { temp, humidity } = weatherData.main;
  const weatherMain = weatherData.weather[0].main;
  const weatherIcon = weatherData.weather[0].icon;
  const windSpeed = weatherData.wind.speed;

  return (
    <div className={`weather-card ${theme === "light" ? "light-mode" : "dark-mode"}`}>
      <div className="weather-top">
        <div className="weather-city">{name}</div>
        <img
          className="weather-icon"
          src={`https://openweathermap.org/img/wn/${weatherIcon}@2x.png`}
          alt={weatherMain}
        />
      </div>
      <div className="weather-info">
        <div className="weather-temp">{Math.round(temp)}°C</div>
        <div className="weather-desc">{weatherMain}</div>
      </div>
      <div className="weather-bottom">
        <div className="weather-bottom-item">
          <span>Humidity</span>
          <span>{humidity}%</span>
        </div>
        <div className="weather-bottom-item">
          <span>Wind</span>
          <span>{windSpeed} m/s</span>
        </div>
      </div>
    </div>
  );
}

export default WeatherWidget;
